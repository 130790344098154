
<template>
  <base-layout-two page-title="Inventory" :page-default-back-link="`/inventories/${$route.params.id}`" end-button-text="Edit"
    :end-button-url="`/inventories/${$route.params.id}/inventory_counts/${$route.params.inventory_count_id}/edit`">
    <section class="ion-padding background-white">
      <h1>{{ preface }} {{ inventory_count.count }} for {{ inventory_count.inventory.name }} </h1>
    </section>

    <div class="padding-8">
      <base-card title="Count Details">
        <base-field-display label-text="Type">
          <p class="ion-text-capitalize">{{ inventory_count.type }}</p>
        </base-field-display>

        <base-field-display label-text="Count">
          <p>{{ inventory_count.count }}</p>
        </base-field-display>

        <base-field-display label-text="Added manually or automatically">
          <p>{{ (inventory_count.manual) ? "Manual" : "Automatic" }}</p>
        </base-field-display>

        <base-field-display label-text="Notes">
          <p>{{ inventory_count.general_notes }}</p>
        </base-field-display>
      </base-card>

    </div>
  </base-layout-two>
</template>

<script>
import { } from "@ionic/vue";

export default {
  components: {
  },

  data() {
    return {
      inventory_count: {
        inventory: {}
      },
    };
  },

  computed: {
    preface() {
      if (this.inventory_count.type === "balance")
        return "Balance of "
      else {
        if (this.inventory_count.count >= 0)
          return "Added +"
        else
          return "Removed "
      }
    }
  },

  async ionViewWillEnter() {
    await this.fetchInventoryCount();
  },

  methods: {
    async fetchInventoryCount() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/inventory_counts/${this.$route.params.inventory_count_id}`)
        .then((response) => {
          this.inventory_count = response.data.inventory_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>